// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$omo-lavanderias-primary: mat.define-palette(mat.$indigo-palette);
$omo-lavanderias-accent: mat.define-palette(
  mat.$pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$omo-lavanderias-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$omo-lavanderias-theme: mat.define-light-theme(
  (
    color: (
      primary: $omo-lavanderias-primary,
      accent: $omo-lavanderias-accent,
      warn: $omo-lavanderias-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($omo-lavanderias-theme);

/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

// regular style toast
@import "ngx-toastr/toastr";

@font-face {
  font-family: 'icomoon';
  src:  url('assets/fonts/icomoon.eot?21226p');
  src:  url('assets/fonts/icomoon.eot?21226p#iefix') format('embedded-opentype'),
    url('assets/fonts/icomoon.ttf?21226p') format('truetype'),
    url('assets/fonts/icomoon.woff?21226p') format('woff'),
    url('assets/fonts/icomoon.svg?21226p#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cancellations:before {
  content: "\e90f";
}
.icon-payment_method:before {
  content: "\e909";
}
.icon-royalties:before {
  content: "\e90a";
}
.icon-settings:before {
  content: "\e90b";
}
.icon-stock:before {
  content: "\e90c";
}
.icon-store:before {
  content: "\e90d";
}
.icon-user:before {
  content: "\e90e";
}
.icon-attendance:before {
  content: "\e900";
}
.icon-cashier:before {
  content: "\e901";
}
.icon-customer:before {
  content: "\e902";
}
.icon-invoice:before {
  content: "\e903";
}
.icon-order:before {
  content: "\e904";
}
.icon-package:before {
  content: "\e905";
}
.icon-pickup:before {
  content: "\e906";
}
.icon-position:before {
  content: "\e907";
}
.icon-promotion:before {
  content: "\e908";
}



html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
textarea {
  resize: none;
}

@layer base {
  input, select {
    @apply rounded border px-3 py-2;
  }

  select {
    @apply min-w-[10rem];
  }

  button {
    @apply px-4 py-2 rounded flex justify-center items-center font-semibold;
  }

  button:disabled {
    @apply opacity-50 text-opacity-90 cursor-not-allowed;
  }

  .button {
    background-color: #3f5266;
  }

  .button:hover {
    background-color: darken($color: #3f5266, $amount: 10) ;
  }
}

.router-link-active {
  background-color: #474B79;
  color: #8CF9EA;
  fill: #8CF9EA;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #88888855;
  @apply rounded;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply bg-hubster-orange
}

:root {
  --hover-primary: #BAECFA;
  --line-color: #A0ABC0;
  --step-complete: #0046FE;
  --primary-color: #202455;
  --secondary-color: #03101F;
  --dark-bg: #DCDFE4;
  --text-gray: #B6B7B8;
  --text-gray-bg: #F3F4F6;
}

.bg-primary-btn {
  background-color: var(--primary-color);
}

.bg-secondary-btn {
  background-color: var(--secondary-color);
}

.border-secondary {
  border-color: var(--secondary-color);
}

.text-secondary {
  color: var(--secondary-color);
}
.amarelo {
  --radio-bg: #FFC866;
}
.areia{
  --radio-bg: #FEF7E2;
}
.azul_bebe {
  --radio-bg: #81CAFD;
}
.azul_escuro {
  --radio-bg: #242C78;
}
.azul_marinho {
  --radio-bg: #130A50;
}
.azul_royal {
  --radio-bg: #128BFF;
}
.azul {
  --radio-bg: #0046FE;
}
.bege {
  --radio-bg: #F6F3DB;
}
.berinjela {
  --radio-bg: #95158E;
}
.branco {
  --radio-bg: #FFFFFF;
}
.caqui {
  --radio-bg: #FFEFD3;
}
.caramelo {
  --radio-bg: #E0A21A;
}
.castanho {
  --radio-bg: #5F292A;
}
.cinza {
  --radio-bg: #868E96;
}
.creme {
  --radio-bg: #FFF8CD;
}
.cru {
  --radio-bg: #FFEFD3;
}
.ferrugem {
  --radio-bg: #9F6500;
}
.gelo {
  --radio-bg: #F6F6F6;
}
.grafite {
  --radio-bg: #343A40;
}
.jeans {
  --radio-bg: #5F9EC5;
}
.jeans_color {
  --radio-bg: #2B3B5D;
}
.laranja {
  --radio-bg: #FE9417;
}
.lilás {
  --radio-bg: #CDB8FA;
}
.marfin {
  --radio-bg: #FFF2F6;
}
.marron {
  --radio-bg: #381C1B;
}
.mostarda {
  --radio-bg: #E29B00;
}
.ouro {
  --radio-bg: linear-gradient(180deg, #FDC13D 0%, #BA850F 100%);
}
.perola {
  --radio-bg: #FFFAF0;
}
.pink {
  --radio-bg: #EB5FB3;
}
.pistache {
  --radio-bg: #A4D641;
}
.prata {
  --radio-bg: linear-gradient(180deg, #F8F9FA 0%, #ADB5BD 100%);
}
.preto {
  --radio-bg: #000000;
}
.salmon {
  --radio-bg: #FA8072;
}
.rosa {
  --radio-bg: #FFB6C1;
}
.turquesa {
  --radio-bg: #11C8BF;
}
.roxo {
  --radio-bg: #8817BD;
}
.verde {
  --radio-bg: #24B24E;
}
.verde_limão {
  --radio-bg: #BBCB06;
}
.vinho {
  --radio-bg: #671537;
}
.verde_musgo {
  --radio-bg: #4B6111;
}
.vermelho {
  --radio-bg: #DF0000;
}
.violeta {
  --radio-bg: #B431E2;
}
